.section-menu {
  background: $red;
  color: $white;
  padding-top: $page-pad/2;
  @include media-breakpoint-down(md) {
    padding-top: 1em;
  } 
  .menu {
    padding: 0 $page-pad;
    max-width: 1200px;
    margin: 0 auto;
    @include media-breakpoint-down(md) {
      padding: 0 1em;
    } 
  }
  .menu-header {
    text-align: center;
    .btn {
      padding: 0.5em 1.25em;
      margin: 1em auto;
    }
    .location {
      text-align: center;
      font-family: $font-dinbold;
      font-size: 20px;
      margin-bottom: 0.25em;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
    h2 {
      text-align: center;
      text-transform: uppercase;
      font-size: 72px;
      font-family: $font-dinmed;
      letter-spacing: -1px;
    }
    @include media-breakpoint-down(md) {
      h2 {
        font-size: 40px;
      }
      .location {
        font-size: 15px;
      }
      margin-bottom: $page-pad/3;
    } 
    margin-bottom: $page-pad;
  }
  .menu-section-name {
      font-size: 20px;
      background: $red;
      margin-bottom: 16px;
      padding: 8px 0;
      color: $red;
      background: $white;
      text-transform: uppercase;
      text-align:center;			 
  }
  .menu-section-name:before{
    content: ".";
    width: 20%;
    height: 2px;
    float:left;
    background: $red; 
    text-indent: -9999px;
    margin-top: 11px;
    margin-left: 4px;
  }
  .menu-section-name:after{
    content:".";
    width: 20%;
    height: 2px;
    float:right;
    background: $red;
    text-indent: -9999px;
    margin-top: 11px;
    margin-right: 4px;
  }
  .section-label {
    font-size: 20px;
    padding: 20px 0;
    text-transform: uppercase;
    font-style: italic;
    text-align: center;
    width: 100%;
    display: block;
  }
  .section-icon {
    display: block;
    height: 80px;
    text-align: center;
    img {
      width: auto;
      max-width: 115px;
      max-height: 100%;
    }
  }
  .item-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-family: $font-dinmed;
    p {
      font-family: $font-body;
      font-size: 18px;
      line-height: 26px;
    }
    li {
      overflow: hidden;
      &:last-child {
        p, .description-price {
          padding-bottom: 0;
        }
        padding-bottom: 20px;
      }
      .item-info {
        overflow: hidden;
        @include media-breakpoint-down(md) {
          margin: 10px 0;
        } 
        .item-head {
          overflow: hidden;
          width: 100%;
          .item-name {
            font-size: 20px;
            text-transform: uppercase;
            float: left;
            max-width: 80%;
            @include media-breakpoint-down(md) {
              max-width: 60%;
              font-size: 18px;
            } 
          }
          .item-price {
            font-size: 18px;
            float: right;            
          }
        }
        p {
          float: left;
          padding: 20px 0;
        }
        .description-price {
          float: right;
          padding: 20px 0;
        }
      }
      .icon {
        text-align: left;
        align-self: center;
        img {
          max-width: 100%;
          height: auto;
        }
        width: 80px;
      }
    }
  }
  .menu-section-sides-drinks {
    .item-list li .item-info .item-head .item-name {
      text-transform: none;
    }
  }
}