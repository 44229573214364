@font-face {
  font-family: 'dinpromedium';
  src: url('../fonts/dinpro-medium_copy-webfont.eot');
  src: url('../fonts/dinpro-medium_copy-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/dinpro-medium_copy-webfont.woff') format('woff'),
       url('../fonts/dinpro-medium_copy-webfont.ttf') format('truetype'),
       url('../fonts/dinpro-medium_copy-webfont.svg#dinpromedium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dinproblack';
  src: url('../fonts/dinpro-black-webfont.eot');
  src: url('../fonts/dinpro-black-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/dinpro-black-webfont.woff') format('woff'),
       url('../fonts/dinpro-black-webfont.ttf') format('truetype'),
       url('../fonts/dinpro-black-webfont.svg#dinproblack') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dinprobold';
  src: url('../fonts/dinpro-bold-webfont.eot');
  src: url('../fonts/dinpro-bold-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/dinpro-bold-webfont.woff') format('woff'),
       url('../fonts/dinpro-bold-webfont.ttf') format('truetype'),
       url('../fonts/dinpro-bold-webfont.svg#dinprobold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dinprolight';
  src: url('../fonts/dinpro-light_copy-webfont.eot');
  src: url('../fonts/dinpro-light_copy-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/dinpro-light_copy-webfont.woff') format('woff'),
       url('../fonts/dinpro-light_copy-webfont.ttf') format('truetype'),
       url('../fonts/dinpro-light_copy-webfont.svg#dinprolight') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'dinpro-regularregular';
  src: url('../fonts/dinpro-regular_copy-webfont.eot');
  src: url('../fonts/dinpro-regular_copy-webfont.eot?#iefix') format('embedded-opentype'),
       url('../fonts/dinpro-regular_copy-webfont.woff') format('woff'),
       url('../fonts/dinpro-regular_copy-webfont.ttf') format('truetype'),
       url('../fonts/dinpro-regular_copy-webfont.svg#dinpro-regularregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'DINNextLTPro-Bold';
  src: url('../fonts/2BBD9E_0_0.eot');
  src: url('../fonts/2BBD9E_0_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/2BBD9E_0_0.woff') format('woff'),
  url('../fonts/2BBD9E_0_0.ttf') format('truetype');
}  
@font-face {
  font-family: 'DINNextLTPro-Light';
  src: url('../fonts/2BBD9E_1_0.eot');
  src: url('../fonts/2BBD9E_1_0.eot?#iefix') format('embedded-opentype'),
  url('../fonts/2BBD9E_1_0.woff') format('woff'),
  url('../fonts/f2BBD9E_1_0.ttf') format('truetype');
}
