// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

//Colours 

$red:                 #c8102e;
$darkred:             #8C0B1F;
$white:               #ffffff;
$black:               #000000;
$ltgrey:              #d6d6d6;


//Fonts

$font-body:             "ff-tisa-sans-web-pro",sans-serif;
$font-dinreg:           "dinpro-regularregular";
$font-dinbold:          "DINNextLTPro-Bold";
$font-dinlight:         "DINNextLTPro-Light";
$font-dinmed:           "dinpromedium",Helvetica,Arial,sans-serif;

//Spacing

$page-pad:              65px;