.wf-loading {
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6,
  p, .menu-item, .copyright-info, .contact-info, .entry-meta,
  .entry-category, .nivoSlider {
    visibility: hidden;
  }
}

.wf-active, .wf-inactive {
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6,
  p, .menu-item, .copyright-info, .contact-info, .entry-meta,
  .entry-category, .nivoSlider {
    visibility: visible;
  }
}
body {
  font-family: $font-body;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
//Text Styles
h3 {
  font-family: $font-dinmed;
  font-size: 34px;
  color: $red;
}
h4 {
  text-transform: uppercase;
  font-family: $font-dinbold;
  font-size: 20px;
  color: $red;
}
h5 {
  font-size: 35px;
  margin-bottom: 1.5em;
  color: $black;
}
h6 {
  font-size: 24px;
  color: $red;
  font-family: $font-dinreg;
  line-height: 32px;
  margin-bottom: 1.5em;
}
a {
  color: $red;
  &:hover {
    color: darken($red, .8);
  }
}

//Logo 

.brand-nav {
  background: url("../images/roast_logo@2x.png") no-repeat;
  height: 45px;
  width: 141px; 
  background-size: contain;
}


//Buttons

.btn {
  -webkit-appearance: none;
  box-shadow: none;
  font-family: $font-dinreg;
  font-size: 1em;
  padding: 0.75em 0.75em 0.75em 0.75em;
  color: $white;
  &:hover {
    color: $white;
  }
  &.btn-primary {
    border: 1px solid $white;
    border-radius: 4px;
    background: $red; 
  }
  &.btn-redghost {
    color: $red;
    border-color: $red;
    background: transparent;
    &:hover {
      color: $white;
      border-color: $white;
    }
  }
  &.btn-whiteghost {
    color: $white;
    border-color: $white;
    background: transparent;
    &:hover {
      background: rgba($white, .2);
    }
  }
  &.btn-boldghost {
    border: 1px solid $white;
    font-family: $font-dinbold;
    border-radius: 0;
    text-transform: uppercase;
    background: transparent;
    &:hover {
      background: rgba($black, 0.5);
    }
  }
}

.woocommerce-checkout .col-1, .woocommerce-checkout .col-2 {
  max-width: 100%;
}
.woocommerce-checkout .form-row {
  display: block;
}
.payment_method_stripe img {
  width:50px;
}

.filters-button-group .button {
  background-color: $red !important;
  margin-right: 5px !important;
  color: #ffffff !important;
  &.is-checked {
    background-color: #000000 !important;
  }
}

section {
	clear:both;
  margin: 0;
}

.posted_in {
  display: none;
}

.product {
  width: 300px !important;
}