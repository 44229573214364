.content-block {
  padding: $page-pad 0;
  @include media-breakpoint-down(md) {
    padding: 1em;
  }  
  .row {
    justify-content: center;
  }
}
.about .content-block {
  padding: $page-pad 15px;
}
.section-video {
  background: $black;
  iframe {
    min-height: 660px;
    @include media-breakpoint-down(md) {
      min-height: 450px;
    }
    @include media-breakpoint-down(sm) {
      min-height: 250px;
    }  
  }
  .more-videos {
    background: rgba($black, .8);
    color: $white;
    font-size: 14px;
    text-transform: uppercase;
    margin: -60px 0 0 15px;
    @include media-breakpoint-down(md) {
      display: none;
    }  
  }
}
.about,
.page-template-template-catering {
  color: $red;
  table {
    td {
      vertical-align: top;
      padding-right: 1em;
    }
  }
  a {
    color: $black;
    &:hover {
      color: $red;
    }
  }
}
.section-catering-menus {
  padding-top: $page-pad;
  .image {
    max-height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-height: 100%;
      width: auto;
      object-fit: cover;
    }
  }
  h4 {
    text-align: center;
    margin: 20px 0;
  }
  ul {
    text-align: center;
    a {
      text-decoration: underline;
      color: $red;
    }
  }
}
.full-photo {
  height: 420px;
  width: 100%;
  background-position: center center;
  background-size: cover;
  @include media-breakpoint-down(md) {
    height: 200px;
  }
}