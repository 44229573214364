.footer-info {
	background: $black;
	padding: $page-pad $page-pad $page-pad/2 $page-pad;
	font-family: $font-dinreg;
	border-top: 1px solid $white;
	.nav-footer {
		.nav {
			display: block;
			text-align: center;
			li {
				display: block;
				padding: 0 0 0.5em 0;
				a {
					&:hover {
						color: $red;
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			display: none;
    }
	}
	.nav-col {
		text-align: center;
		.btn-redghost {
			margin: 20px 0;
			display: inline-block;
		}
	}
	.locations-col {
		position: relative;
		@include media-breakpoint-down(md) {
			text-align: center;
			font-size: 15px;
			margin-top: 1.5em;
    }
		li {
			a {
				color: $white;
				padding-bottom: 0.25em;
				display: block;
				&:hover {
					color: $red;
				}
			}
		}
		.copyright {
			position: absolute;
			bottom: -10px;
			left: 15px;
			font-size: .875em;
			color: $white;
			text-align: right;
		}
	}
	@include media-breakpoint-down(md) {
		padding: 1em;
		.locations-col {
			padding-bottom: 3em;
			.copyright {
				text-align: left;
			}
		}
	}
}
.services {
  li {
    margin-bottom: 5px;
	color: $white;
  }

  .service-logo {
    max-width: 200px;
    height: auto;
  }
}
.footer-logo {
	background: url(../images/footer-logo@2x.png) no-repeat;
	background-size: 281px 203px;
	width: 281px;
	height: 203px;
	margin: auto;
	display: block;
	background-position: center;
	position: relative;
	@include media-breakpoint-down(md) {
		width: 200px;
		height: 143px;
		background-size: 100% 100%;
		margin-bottom: 20px;
	}
	.footer-anchor{
		position: absolute;
	}
	.anchor-porchetta{
		width: 70px;
		height: 70px;
		top: 15px;
		right: 0;
	}
	.anchor-beef{
		width: 70px;
		height: 70px;
		bottom: 28px;
		left: 0;
	}
	.anchor-chicken{
		width: 70px;
		height: 70px;
		top: 12px;
		left: 0;
	}
	.anchor-frito{
		width: 70px;
		height: 70px;
		right: 0;
		bottom: 30px;
	}
	.anchor-bowls{
		width: 70px;
		height: 70px;
		left: 100px;
		bottom: 0;
	}
	.anchor-salad{
		width: 70px;
		height: 70px;
		left: 100px;
		top: 0;
	}
}
