.section-our-food {
  .availability {
    text-transform: uppercase;
    font-family: $font-dinmed;
    width: 100%;
    padding: 0 $page-pad 0 $page-pad;
    .title {
      display: inline-block;
    }
    ul {
      list-style: none;
      display: inline-block;
      padding: 0;
      li {
        display: inline-block;
        &:last-child {
          .comma {
            display: none;
          }
        }
      }
    }
    @include media-breakpoint-down(md) {
      padding: 1em;
      font-size: 14px;
    }
  }
  .food-content {
    padding: 0 $page-pad $page-pad;
    color: $red;
    @include media-breakpoint-down(md) {
      padding: 1em 1em 1em 1em;
    }
  }
  .food-container {
    max-width: 1440px;
    margin: 0 auto;
  }
  h3 {
    padding: $page-pad $page-pad 20px $page-pad;
    color: $red;
    width: 100%;
    @include media-breakpoint-down(md) {
      padding: 0 0.5em 0.5em 0.5em;
    }
  }
  .food-banner {
    height: 420px;
    width: 100%;
    background-position: center center;
    background-size: cover;
    @include media-breakpoint-down(md) {
      height: 200px;
    }
  }
}
.food-nav {
  display: flex;
  margin: 0 $page-pad $page-pad $page-pad;
  padding: 20px 0;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $ltgrey;
  @include media-breakpoint-down(md) {
    margin: 0;
  }
  .nav-item {
    flex: 1;
    .nav-link {
      text-align: center;
      border: 1px solid $white;
      margin: 0 15px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include media-breakpoint-down(md) {
        height: 50px;
        width: 60px;
      }
      &:hover, &.active {
        border-color: $red;
        background: transparent;
        color: $red;
      }
      img {
        max-height: 100%;
        width: auto;
        max-width: 50px;
        @include media-breakpoint-down(md) {
          max-width: 30px;
        }
      }
    }
  }
}