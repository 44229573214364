.section-social {
  background: $black;
  color: $red;
  padding: $page-pad;
  @include media-breakpoint-down(md) {
    padding: 1em;
  } 
  h4 {
    margin-bottom: 20px;
  }
  .tweets {
    color: $white;
    font-size: 1.125em;
    font-family: $font-dinreg;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    .timePosted {
      font-size: 0.77em;
      text-decoration: underline;
    }
  }
  .social-instagram-container {
    a {
      display: block;
      max-height: 225px;
      img {
        object-fit: scale-down;
        height: 225px;
      }
    }
  }
}
.section-contact {
  h4 {
    margin-bottom: 20px;
  }
  .map {
    padding-right: 0;
    @include media-breakpoint-down(md) {
      padding-left: 0;
    }
  }
  .contact-col {
    padding: $page-pad 0 $page-pad/2 $page-pad;
    justify-content: center;
    @include media-breakpoint-down(md) {
      padding: 1em;
    }
    ul {
      list-style: none;
    }
    &.hours {
      padding-left: $page-pad/2;
      @include media-breakpoint-down(md) {
        padding: 1em;
      }
      li {
        margin-bottom: 10px;
      }
      .day {
        text-transform: uppercase;
      }
    }
    .contact-social-links {
      a {
        text-align: left;
        margin-right: 0.5em;
        font-size: 26px;
      }
    }
    .btn-primary {
      padding: 0.5em 1.25em;
    }
  }
}
.cta {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgba($black, .5);
  background-blend-mode: multiply;
  min-height: 260px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .cta-link {
    font-size: 20px;
  }
  @include media-breakpoint-down(md) {
    min-height: 160px;
    .cta-link {
      font-size: 15px;
    }
  } 
}