
header.banner {
	color: $white;
  background: $red;
}
.nav {
  list-style: none;
  display: flex;
  .menu-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: .75em;
    a {
      display: block;
      text-align: center;
      position: relative;
      text-transform: uppercase;
      font-family: $font-dinmed;
      color: $white;
      letter-spacing: 0.21px;
      &:hover {
        text-decoration: none;
        color: darken($white, 10%);
      }
    }
    &.menu-roast {
      a {
        margin: 0 2.5em;
        overflow: hidden;
        text-indent: -999px;
        @extend .brand-nav;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    font-size: 0.85em;
  }
}
.nav-header {
  .menu-item {
    &.menu-order-online a {
      background-image: url('../images/mobile-alt-solid.svg');
      background-size: 15px;
      background-repeat: no-repeat;
      background-position: 10px center;
      font-weight: bold;
      border: 1px solid $white;
      background-color: $black;
      transition: all 400ms ease;
      padding: 0.5em 0.5em 0.5em 2em;

      &:hover {
        background-color: $darkred;
      }

      @include media-breakpoint-down(lg) {
        background: transparent;
        padding: 0.5em;
      }
    }
  }
}
.drop-container {
  padding: 0;
  .row {
    margin: 0;
  }
}
.nav-primary-order-online,
.nav-primary-locations {
  display: none;
  position: absolute;
  width: 101%;
  z-index: 99;
  background: $red;
}

.drop-nav {
  border-top: 1px solid $white;
  margin-left: -1px;
  .nav-primary-location {
    position: relative;
    padding: 0;
    border-left: 1px solid $white;
    height: 284px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-size: cover;
    background-position: center center;
    @include media-breakpoint-down(md) {
      height: 150px;
      border-left: 0;
      border-bottom: 1px solid $white;
    }
    .location-back {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: $red;
      background-blend-mode: multiply;
      opacity: .8;
      z-index: 0;
    }
    * {
      position: relative;
      z-index: 1;
    }
    .location-name {
      font-family: $font-dinbold;
      font-size: 41px;
      line-height: 1em;
      max-width: 284px;
      text-align: center;
      @include media-breakpoint-down(md) {
        font-size: 20px;
      }
    }
    .location-city {
      font-size: 17px;
      font-family: $font-dinmed;
      letter-spacing: 0.21px;
      margin: 10px 0 20px 0;
      text-transform: uppercase;
      @include media-breakpoint-down(md) {
        font-size: 12px;
      }
    }
    .button-box {
      text-align: center;
      .btn {
        padding: 0.25em 1em 0.45em 1em;
        margin: 0 0.25em;
      }
    }
  }
}

.nav-primary-order-online .row {
  background-image: url('../images/IMG_0823.jpg');
  background-size: cover;
  background-position: center bottom;

  .location-name {
    margin-bottom: 15px;
  }
}

.single-locations {
  .slide_image {
    background-color: rgba($black, .5);
    background-blend-mode: multiply;
    .title {
      @include media-breakpoint-down(md) {
        font-size: 30px;
      }
    }
  }
}
.header-gallery {
  .flickity-viewport {
    width: 100%;
    .slide_image {
      height: 500px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: $white;
      @include media-breakpoint-down(md) {
        height: 300px;
      }
      .title {
        font-size: 70px;
        font-family: $font-dinreg;
        text-shadow: 4px 4px 13px rgba(0,0,0,0.70);
        line-height: 1em;
        text-transform: uppercase;
        @include media-breakpoint-down(md) {
          font-size: 50px;
        }
      }
      .location {
        text-align: center;
        font-family: $font-dinbold;
        font-size: 20px;
        margin: 1em 0 0.75em 0;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }
      .callout-link {
        margin-top: 20px;
        .btn {
          padding: 0.5em 1.5em;
        }
      }
      .row {
        width: 100%;
        height: 100%;
      }
      .callout {
        padding: 1em;
        height: 200px;
        margin-bottom: $page-pad;
        position: relative;
        background: $red;
        align-self: flex-end;
        text-align: left;
        .callout-title {
          font-family: $font-dinlight;
          letter-spacing: .05em;
          font-size: 32px;
          @include media-breakpoint-down(lg) {
            font-size: 25px;
          }
          &:hover {
            text-decoration: none;
          }
        }
        p {
          margin: 0.25em 0;
          padding-top: 10px;
          font-size: 15px;
          padding-bottom: 10px;
          color: $white;
          border-top: 1px solid $white;
          }
          a {
            color: $white;
          }
          .callout-link {
            text-decoration: underline;
            .fa {
              margin-left: 0.5em;
            }
          }
        }
      }
  }
  .flickity-button {
    background: transparent;
    background-image: url('../images/angle.png');
    background-size: 24px 45px;
    background-repeat: no-repeat;
    @include media-breakpoint-down(md) {
      display: none;
    }
    .flickity-button-icon {
      display: none;
    }
    &.next {
      transform: rotate(180deg);
    }
  }
}
.mobile-nav {
  .mobile-toggle {
    overflow: hidden;
    width: 100%;
    padding: 0.5em 1em;
    -webkit-appearance: none;
    background: $red;
    border: 0;
    &.order-toggle,
    &.locations-toggle {
      background: $white;
      padding: 0.5em 1em;
      border-top: 0;
      border-bottom: 1px solid $red;
      .title {
        text-transform: uppercase;
        font-family: $font-dinmed;
        font-size: 12px;
        color: $red;
        width: 60%;
        float: left;
        text-align: left;
      }
      .fa {
        font-size: 1.25em;
        margin-right: 0.25em;
        color: $red;
      }
    }
    .brand-nav {
      float: left;
      max-height: 25px;
    }
    .fa {
      float: right;
      font-size: 1.5em;
      color: $white;
    }
  }

  .nav-order-mobile {
    .nav-primary-location {
      height: auto;
      padding: 20px;
    }

    .location-name {
      margin-bottom: 10px;
    }
  }

  .menu-roast {
    display: none;
  }
  .drop-container {
    display: none;
  }
  .nav-primary {
    background: $red;
    padding-bottom: $page-pad/2;
    display: none;
    .nav {
      display: block;
    }
  }
}
